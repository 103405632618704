<template>
  <div>
    <div style="margin: 10px;padding: 5px;border: 1px solid #ddd;">
      <div style="margin-top: 10px;">钱包地址</div>
      <div style="margin-top: 10px;">{{us.address}}</div>
    </div>

    <div style="margin: 10px;padding: 5px;border: 1px solid #ddd;">
      <div style="margin-top: 10px;">币池信息</div>
      <div style="margin-top: 10px;">{{pool.usde}} USDE</div>
      <div style="margin-top: 10px;">{{pool.eds}} EDS</div>
      <div style="margin-top: 10px;">1 EDS = {{swapPrice}} USDE</div>
    </div>

    <div style="margin: 10px;padding: 5px;border: 1px solid #ddd;">
      <div style="margin-top: 10px;">钱包余额</div>
      <div style="margin-top: 10px;">USDT: {{balance.usdt}}</div>
      <div style="margin-top: 10px;">USDE: {{balance.usde}}</div>
      <div style="margin-top: 10px;">EDS : {{balance.eds}}</div>
    </div>

    <div style="margin: 10px;padding: 5px;border: 1px solid #ddd;">
      <div style="margin-top: 10px;"><q-input outlined dense v-model="approveAmount" type="number" placeholder="请输入授权金额" /></div>
      <div style="margin-top: 10px;"><button @click="approve(1)">授权USDT</button> <button style="margin-left: 5px;" @click="approve(2)">授权USDE</button> <button style="margin-left: 5px;" @click="approve(3)">授权EDS</button></div>
    </div>

    <div style="margin: 10px;padding: 5px;border: 1px solid #ddd;">
      <div style="margin-top: 10px;">已授权</div>
      <div style="margin-top: 10px;">USDT: {{allowances.usdt}}</div>
      <div style="margin-top: 10px;">USDE: {{allowances.usde}}</div>
      <div style="margin-top: 10px;">EDS : {{allowances.eds}}</div>
    </div>

    <div style="margin: 10px;padding: 5px;border: 1px solid #ddd;">
      <div style="margin-top: 10px;">购买机器人</div>
      <div style="margin-top: 10px;"><q-input outlined dense v-model="botAmount" type="number" placeholder="请输入购买金额" /></div>
      <div style="margin-top: 10px;"><button @click="buyBot(1)">购买α1</button> <button style="margin-left: 5px;" @click="buyBot(4)">购买β1</button></div>
    </div>

    <div style="margin: 10px;padding: 5px;border: 1px solid #ddd;">
      <div style="margin-top: 10px;">闪兑</div>
      <div style="margin-top: 10px;"><q-input outlined dense v-model="swapAmount" type="number" placeholder="请输入闪兑金额" /></div>
      <div style="margin-top: 10px;"><button @click="swap(1)">USDE闪兑EDS</button> <button style="margin-left: 5px;" @click="swap(2)">EDS闪兑USDE</button></div>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import Web3Helper from "../../assets/js/web3helper.js";

  export default {
    name: '',
    components: {},
    setup() {
      return {
        us: ref(null),

        web3SWAP: ref({}),
        web3USDT: ref({}),
        web3USDE: ref({}),
        web3EDS: ref({}),

        swapPrice: ref(0),

        pool: ref({
          usde:0,
          eds:0  
        }),

        balance: ref({
          usdt: 0,
          usde: 0,
          eds: 0
        }),

        allowances: ref({
          usdt: 0,
          usde: 0,
          eds: 0
        }),

        approveAmount: ref(''),
        botAmount: ref(''),
        swapAmount: ref('')
      }
    },
    created() {
      this.us = JSON.parse(this.$utils.getloc("us"))
      this.getInfo()
    },
    methods: {
      getInfo() {
        let _this = this
        _this.$request.post(
          "api/EthContract/Info",
          {},
          (res) => {
            if (res.data.code == 0) {
              _this.$q.dialog({
                message: res.data.msg,
              });
            }
            let data = res.data.data

            var swap = data.contractList.find(item => item.id == 99);
            var usdt = data.contractList.find(item => item.id == 11);
            var usde = data.contractList.find(item => item.id == 12);
            var eds = data.contractList.find(item => item.id == 13);

            _this.web3SWAP = new Web3Helper(swap.abi, swap.address);
            _this.web3USDT = new Web3Helper(usdt.abi, usdt.address);
            _this.web3USDE = new Web3Helper(usde.abi, usde.address);
            _this.web3EDS = new Web3Helper(eds.abi, eds.address);

            _this.balanceOf(swap.address, 4);
            _this.balanceOf(swap.address, 5);

            _this.balanceOf(_this.us.address, 1);
            _this.balanceOf(_this.us.address, 2);
            _this.balanceOf(_this.us.address, 3);

            _this.allowance(_this.us.address, 1);
            _this.allowance(_this.us.address, 2);
            _this.allowance(_this.us.address, 3);

            _this.getPrice();
          }
        )
      },
      // 获取 SWAP 币价
      getPrice() {
        let _this = this
        _this.web3SWAP.getPrice().then((res) => {
          _this.swapPrice = _this.web3SWAP.fromWei(res, 18);
        });
      },
      // 获取余额
      balanceOf(address, lx) {
        let _this = this;
        if (lx == 1) {
          _this.web3USDT.contractBalanceOf(address).then((wei) => {
            _this.balance.usdt = _this.web3USDT.fromWei(wei, 18);
          });
        } else if (lx == 2) {
          _this.web3USDE.contractBalanceOf(address).then((wei) => {
            _this.balance.usde = _this.web3USDE.fromWei(wei, 18);
          });
        } else if (lx == 3) {
          _this.web3EDS.contractBalanceOf(address).then((wei) => {
            _this.balance.eds = _this.web3EDS.fromWei(wei, 18);
          });
        } else if (lx == 4) {
          _this.web3USDE.contractBalanceOf(address).then((wei) => {
            _this.pool.usde = _this.web3USDE.fromWei(wei, 18);
          });
        } else if (lx == 5) {
          _this.web3EDS.contractBalanceOf(address).then((wei) => {
            _this.pool.eds = _this.web3EDS.fromWei(wei, 18);
          });
        }
      },
      // 获取已授权金额
      allowance(address, lx) {
        let _this = this
        if (lx == 1) {
          _this.web3USDT.contractAllowance(address, _this.web3SWAP.contractAddress).then((wei) => {
            _this.allowances.usdt = _this.web3USDT.fromWei(wei, 18);
          });
        } else if (lx == 2) {
          _this.web3USDE.contractAllowance(address, _this.web3SWAP.contractAddress).then((wei) => {
            _this.allowances.usde = _this.web3USDE.fromWei(wei, 18);
          });
        } else if (lx == 3) {
          _this.web3EDS.contractAllowance(address, _this.web3SWAP.contractAddress).then((wei) => {
            _this.allowances.eds = _this.web3EDS.fromWei(wei, 18);
          });
        }
      },
      // 执行授权
      approve(lx) {
        let _this = this;
        if (_this.approveAmount == "") {
          _this.$q.dialog({
            message: "请输入授权金额",
          });
          return
        }

        let payWei = _this.web3USDT.toWei(_this.approveAmount, 18);

        var web3Contract;
        if (lx == 1) {
          web3Contract = _this.web3USDT;
        }else if (lx == 2) {
          web3Contract = _this.web3USDE;
        }else if (lx == 3) {
          web3Contract = _this.web3EDS;
        }

        _this.$q.loading.show({
          message: _this.$t('等待交易完成')
        })
        web3Contract.contractApprove(_this.us.address, _this.web3SWAP.contractAddress, payWei.toString()).then((txid) => {
          _this.$q.loading.hide();
          _this.$q.dialog({
            message: "授权成功",
          });
          _this.balanceOf(_this.us.address, lx);
          _this.allowance(_this.us.address, lx);
        }, (err) => {
          _this.$q.loading.hide();
          //过滤4001,4001=拒绝签名
          if (err.code != 4001) {
            _this.$q.dialog({
              title: err.code,
              message: err.message,
            });
          }
        });
      },
      // 购买机器人
      buyBot(botId) {
        let _this = this
        if (_this.botAmount == "") {
          _this.$q.dialog({
            message: "请输入购买金额",
          });
          return
        }

        let payWei = _this.web3SWAP.toWei(_this.botAmount, 18);
        _this.$q.loading.show({
          message: _this.$t('等待交易完成')
        })
        _this.web3SWAP.contractBuyBot(_this.us.address, botId, payWei.toString()).then((txid) => {
            _this.$q.loading.hide();
            _this.$q.dialog({
              message: "购买成功",
            });
            _this.balanceOf(_this.us.address, 1);
            _this.balanceOf(_this.us.address, 3);
            _this.allowance(_this.us.address, 1);
            _this.allowance(_this.us.address, 3);
          }, (err) => {
            _this.$q.loading.hide();
            //过滤4001,4001=拒绝签名
            if (err.code != 4001) {
              _this.$q.dialog({
                title: err.code,
                message: err.message,
              });
            }
          });
      },

      // USDE兑换EDS
      swap(lx) {
        let _this = this
        if (_this.swapAmount == "") {
          _this.$q.dialog({
            message: "请输入闪兑金额",
          });
          return
        }

        let payWei = _this.web3SWAP.toWei(_this.swapAmount, 18);
        _this.$q.loading.show({
          message: _this.$t('等待交易完成')
        })
        if(lx == 1) {
          _this.web3SWAP.contractUsdeExchangeEds(_this.us.address, payWei.toString()).then((txid) => {
            _this.$q.loading.hide();
            _this.$q.dialog({
              message: "闪兑成功",
            });
            _this.balanceOf(_this.us.address, 2);
            _this.balanceOf(_this.us.address, 3);
          }, (err) => {
            _this.$q.loading.hide();
            //过滤4001,4001=拒绝签名
            if (err.code != 4001) {
              _this.$q.dialog({
                title: err.code,
                message: err.message,
              });
              _this.balanceOf(_this.us.address, 2);
              _this.balanceOf(_this.us.address, 3);
            }
          });
        } else if(lx == 2){
          _this.web3SWAP.contractEdsExchangeUsde(_this.us.address, payWei.toString()).then((txid) => {
            _this.$q.loading.hide();
            _this.$q.dialog({
              message: "闪兑成功",
            });
          }, (err) => {
            _this.$q.loading.hide();
            //过滤4001,4001=拒绝签名
            if (err.code != 4001) {
              _this.$q.dialog({
                title: err.code,
                message: err.message,
              });
            }
          });
        }
      }
    }
  }
</script>

<style scoped>

</style>